import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function Trouble() {
  return (
    <Layout>
      <Helmet title="Trouble | Ray LaMontagne" />

      <h1>Trouble</h1>

      <h2>Ray LaMontagne</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Trouble...</p>
          <p>Trouble, trouble, trouble, trouble</p>
          <p>Trouble been doggin' my soul since the day I was born</p>
        </Verse>
        <Verse>
          <p>Worry...</p>
          <p>Worry, worry, worry, worry</p>
          <p>Worry just will not seem to leave my mind alone</p>
        </Verse>
        <PreChorus>
          <p>We'll I've been...</p>
          <p>Saved by a woman</p>
          <p>I've been...</p>
          <p>Saved by a woman</p>
          <p>I've been...</p>
          <p>Saved by a woman</p>
        </PreChorus>
        <Chorus>
          <p>She won't let me go</p>
          <p>She won't let me go now</p>
          <p>She won't let me go</p>
          <p>She won't let me go now</p>
        </Chorus>
        <Verse>
          <p>Trouble...</p>
          <p>Oh, trouble, trouble, trouble, trouble</p>
          <p>Feels like every time I get back on my feet</p>
          <p>She come around and knock me down again</p>
        </Verse>
        <Verse>
          <p>Worry...</p>
          <p>Oh, worry, worry, worry, worry</p>
          <p>Sometimes I swear it feels like this worry is my only friend</p>
        </Verse>
        <PreChorus>
          <p>We'll I've been saved...</p>
          <p>By a woman</p>
          <p>I've been saved...</p>
          <p>By a woman</p>
          <p>I've been saved...</p>
          <p>By a woman</p>
        </PreChorus>
        <Chorus>
          <p>She won't let me go</p>
          <p>She won't let me go now</p>
          <p>She won't let me go</p>
          <p>She won't let me go now</p>
        </Chorus>
        <Bridge>
          <p>Oh..., Ahhhh...</p>
          <p>Ohhhh</p>
          <p>She good to me now</p>
          <p>She gave me love and affection</p>
          <p>She good tell me now</p>
          <p>She gave me love and affection</p>
          <p>I Said I love her</p>
          <p>Yes I love her</p>
          <p>I said I love her</p>
          <p>I said I love...</p>
          <p>She good to me now</p>
          <p>She's good to me</p>
          <p>She's good to me</p>
        </Bridge>
      </Columns>
    </Layout>
  );
}
